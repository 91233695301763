<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" class="text-center">
                <v-icon size="120" class="mb-4">mdi-alert-circle-outline</v-icon>
                <h1 class="display-2 mb-3">Oups! Page non trouvée</h1>
                <p class="subheading mb-5">
                    Désolé, la page que vous recherchez semble introuvable.
                </p>
                <v-btn color="secondary" large :to="{ name: 'Home' }">
                    Retourner à la page d'accueil
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
export default {
    name: 'NotFound'
};
</script>

<style>
/* Votre style ici */
</style>