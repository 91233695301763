import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12","sm":"8","md":"6"}},[_c(VIcon,{staticClass:"mb-4",attrs:{"size":"120"}},[_vm._v("mdi-alert-circle-outline")]),_c('h1',{staticClass:"display-2 mb-3"},[_vm._v("Oups! Page non trouvée")]),_c('p',{staticClass:"subheading mb-5"},[_vm._v(" Désolé, la page que vous recherchez semble introuvable. ")]),_c(VBtn,{attrs:{"color":"secondary","large":"","to":{ name: 'Home' }}},[_vm._v(" Retourner à la page d'accueil ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }